import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "me-7 mb-4" }
const _hoisted_5 = { class: "symbol symbol-100px symbol-lg-160px symbol-fixed position-relative w-150px h-150px d-flex justify-content-center align-items-start overflow-hidden" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "w-100 h-auto",
  src: "media/avatars/blank.png",
  alt: ""
}
const _hoisted_8 = { class: "flex-grow-1" }
const _hoisted_9 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "d-flex align-items-center mb-2" }
const _hoisted_12 = { class: "text-gray-800 fs-2 fw-bolder me-1" }
const _hoisted_13 = {
  key: 0,
  class: "svg-icon svg-icon-1 svg-icon-primary"
}
const _hoisted_14 = {
  key: 1,
  class: "svg-icon svg-icon-1 svg-icon-danger"
}
const _hoisted_15 = {
  key: 2,
  class: "btn btn-sm btn-info fw-bolder ms-2 fs-8 py-1 px-3"
}
const _hoisted_16 = { class: "d-flex flex-wrap fw-bold fs-6 mb-4 pe-2" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_19 = {
  key: 1,
  class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
}
const _hoisted_20 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_21 = {
  key: 2,
  class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
}
const _hoisted_22 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_25 = {
  key: 4,
  class: "d-flex align-items-center text-gray-400 text-hover-primary mb-2"
}
const _hoisted_26 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_27 = { class: "d-flex flex-wrap flex-stack" }
const _hoisted_28 = { class: "d-flex flex-column flex-grow-1 pe-8" }
const _hoisted_29 = { class: "d-flex flex-wrap" }
const _hoisted_30 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_31 = { class: "d-flex align-items-center" }
const _hoisted_32 = { class: "svg-icon svg-icon-3 svg-icon-success me-2" }
const _hoisted_33 = ["data-kt-countup-value"]
const _hoisted_34 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_35 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_36 = { class: "d-flex align-items-center" }
const _hoisted_37 = { class: "svg-icon svg-icon-3 svg-icon-info me-2" }
const _hoisted_38 = ["data-kt-countup-value"]
const _hoisted_39 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_40 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_41 = { class: "d-flex align-items-center" }
const _hoisted_42 = { class: "svg-icon svg-icon-3 svg-icon-success me-2" }
const _hoisted_43 = ["data-kt-countup-value"]
const _hoisted_44 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_45 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_46 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_47 = { class: "nav-item" }
const _hoisted_48 = { class: "nav-item" }
const _hoisted_49 = { class: "nav-item" }
const _hoisted_50 = { class: "nav-item" }
const _hoisted_51 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.formData.photo_url)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "w-100 h-auto",
                    src: _ctx.formData.photo_url,
                    alt: ""
                  }, null, 8, _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7))
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.formData.last_name) + " " + _toDisplayString(_ctx.formData.first_name), 1),
                  (_ctx.formData.status == 10)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen048.svg" })
                      ]))
                    : (_ctx.formData.status == 25)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen050.svg" })
                        ]))
                      : _createCommentVNode("", true),
                  (_ctx.formData.assigned_group)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.getGroupName(_ctx.formData.assigned_group)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  (_ctx.formData.mobile && _ctx.userInfo.advanced_manage)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: 'tel:' + _ctx.formData.mobile,
                        class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                      }, [
                        _createElementVNode("span", _hoisted_18, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/communication/com006.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.formData.mobile), 1)
                      ], 8, _hoisted_17))
                    : _createCommentVNode("", true),
                  (_ctx.formData.wechat_social_connection[0])
                    ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                        _createElementVNode("span", _hoisted_20, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/communication/com012.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.formData.wechat_social_connection[0]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.formData.tecrm_address.city)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen018.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.formData.tecrm_address.city), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.formData.email && _ctx.userInfo.advanced_manage)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 3,
                        href: 'mailto:' + _ctx.formData.email,
                        class: "d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                      }, [
                        _createElementVNode("span", _hoisted_24, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/communication/com011.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.formData.email), 1)
                      ], 8, _hoisted_23))
                    : _createCommentVNode("", true),
                  (_ctx.formData.__show.channel)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                        _createElementVNode("span", _hoisted_26, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/abstract/abs021.svg" })
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.formData.__show.channel), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      _createElementVNode("span", _hoisted_32, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/finance/fin009.svg" })
                      ]),
                      _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": 
                        _ctx.formData.account_extend?.total_order_amount_gmv
                      
                      }, _toDisplayString(_ctx.formData.account_extend?.total_order_amount_gmv), 9, _hoisted_33)
                    ]),
                    _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.t("common.totalOrAmount")) + "(GMV) ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("span", _hoisted_37, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/finance/fin009.svg" })
                      ]),
                      _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": 
                        _ctx.formData.account_extend?.total_order_amount_gtv
                      
                      }, _toDisplayString(_ctx.formData.account_extend?.total_order_amount_gtv), 9, _hoisted_38)
                    ]),
                    _createElementVNode("div", _hoisted_39, _toDisplayString(_ctx.t("common.totalOrAmount")) + "(GTV) ", 1)
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("span", _hoisted_42, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr066.svg" })
                      ]),
                      _createElementVNode("div", {
                        class: "fs-2 fw-bolder",
                        "data-kt-countup": "true",
                        "data-kt-countup-value": 
                        _ctx.formData.account_extend?.total_orders_gmv
                      
                      }, _toDisplayString(_ctx.formData.account_extend?.total_orders_gmv), 9, _hoisted_43)
                    ]),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.t("influencerAccount.totalOrders")), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("ul", _hoisted_46, [
            _createElementVNode("li", _hoisted_47, [
              _createVNode(_component_router_link, {
                to: 
                '/influencer-platform/influencer-accounts/' +
                _ctx.$route.params.id +
                '/overview'
              ,
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("common.overview")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_48, [
              _createVNode(_component_router_link, {
                to: 
                '/influencer-platform/influencer-accounts/' +
                _ctx.$route.params.id +
                '/information'
              ,
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("common.information")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_49, [
              _createVNode(_component_router_link, {
                to: 
                '/influencer-platform/influencer-accounts/' +
                _ctx.$route.params.id +
                '/profile'
              ,
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("influencerAccount.profile")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_50, [
              _createVNode(_component_router_link, {
                to: 
                '/influencer-platform/influencer-accounts/' +
                _ctx.$route.params.id +
                '/interactions'
              ,
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("influencerAccount.interactions")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("li", _hoisted_51, [
              _createVNode(_component_router_link, {
                to: 
                '/influencer-platform/influencer-accounts/' +
                _ctx.$route.params.id +
                '/capability-growth'
              ,
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("influencerAccount.capabilityAndGrowth")), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}