
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiAccount, ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface User {
  id: number;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  language: string;
  advanced_manage: number;
}

interface TaggingItem {
  label: string;
  value: number;
}

export default defineComponent({
  name: "account-detail",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();

    const userInfo = ref<User>();
    userInfo.value = store.getters.currentUser;

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const formData = ref({
      status: 10,
      crm_account_id: "",
      crm_account_status: 10,
      salutation: "",
      first_name: "",
      last_name: "",
      source: "",
      channel: "",
      industry: "",
      mb_segmentation: [],
      email: "",
      phone: "",
      birthday: "",
      mobile_country_code: "",
      mobile: "",
      location_of_mobile: "",
      wechat_account: "",
      bc_quota: "",
      photo_url: "",
      stage: 0,
      wechat_social_connection: [],
      tecrm_address: {
        city: "",
      },
      account_extend: {
        total_order_amount_gmv: "",
        total_order_amount_gtv: "",
        total_orders_gmv: "",
      },
      __show: {
        channel: "",
      },
    });

    const groupOptions = ref<Array<TaggingItem>>([]);

    ApiAccount.getAccountInfo({ id: route.params.id })
      .then(({ data }) => {
        if (data.code == 0) {
          formData.value = data.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getGroupOptions = async () => {
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      if (data.code == 0) {
        groupOptions.value = data.data;
      }
    };

    const getGroupName = (id) => {
      let group = groupOptions.value.find((item) => {
        return item.value == id;
      });
      return group?.label;
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      getGroupOptions();
    });

    return {
      t,
      formData,
      groupOptions,
      userInfo,
      getGroupName,
    };
  },
});
